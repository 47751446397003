import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addUserToACourse, fetchSingleCourse } from '../../utils/courseFunctions'
import SelectUI from '../../CustomUIElements/Inputs/Select'
import { Buttons } from '../../CustomUIElements/Buttons/Buttons'
import { toast } from 'react-toastify'
import Scale from '../../CustomUIElements/Loaders/ScaleLoader'
import { fetchLecturers } from '../../utils/fetchLecturers'
import { useQuery } from 'react-query'
import useAuthStore from '../../store/authStore'
import parse from "html-react-parser"
import { fetchStudents } from '../../utils/students'
import { useHandleApiError } from '../../hooks/useHandleApiError'

function SingleCourse() {
    const { id } = useParams()
    const [selectedUserType, setSelectedUserType] = useState(null)
    const currentRoute = typeof window !== "undefined" ? window.location.pathname : "";
    const { handleError } = useHandleApiError(currentRoute);
    const [courseInfo, setCourseInfo] = useState(null)
    const [loading, setLoading] = useState({
        initialLoad: false,
        attachLoad: false
    })
    const [users, setUsers] = useState([])
    const { removeUser, userProfile } = useAuthStore()

    const { isLoading, refetch } = useQuery("fetchLecturers", fetchLecturers, {
        enabled: userProfile?.user?.userRole === "LearningInstitution",
        refetchOnWindowFocus: true,
        onSuccess: ({ data }) => {
            console.log(data.data)
            const resData = data?.data?.map((u) => ({ value: u?.id, label: `${u?.firstName} ${u?.lastName}` }));
            setUsers(resData);
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                // removeUser()
                // window.location.href = "/login"
                handleError(response)
            }
        }
    })
    const { } = useQuery("fetchStudents", fetchStudents, {
        enabled: userProfile?.user?.userRole === "Lecturer",
        refetchOnWindowFocus: true,
        onSuccess: ({ data }) => {
            console.log(data.data)
            const resData = data?.data?.map((u) => ({ value: u?.id, label: `${u?.firstName} ${u?.lastName}` }));
            setUsers(resData);
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                // removeUser()
                // window.location.href = "/login"
                handleError(response)
            }
        }
    })
    useEffect(() => {
        const fetchCourseInfo = async () => {
            setLoading({ ...loading, initialLoad: true })
            await fetchSingleCourse({ id }).then((res) => {
                // console.log(res?.data?.data)
                setCourseInfo(res?.data?.data)
                setLoading({ ...loading, initialLoad: false })
            }).catch((err) => {
                console.log(err)
                setLoading({ ...loading, initialLoad: false })
            })
        }
        if (id) {
            fetchCourseInfo()
        }
    }, [id])

    const handleAdduser = async (e) => {
        e.preventDefault()
        setLoading({ ...loading, attachLoad: true })
        await addUserToACourse({ value1: parseInt(id), value2: selectedUserType.value }).then((res) => {
            console.log(res?.data)
            setLoading({ ...loading, attachLoad: false })
            toast.success(`${userProfile?.user?.userRole === "Lecturer" ? "Student" : "Lecturer"} added to course successfully`)
            setSelectedUserType(null)
        }).catch((err) => {
            console.log(err)
            if (err?.response?.status === 500) {
                toast.error(`${err?.response?.data}`)
            } else {

                toast.error("Failed to add lecturer to course")
            }
            setLoading({ ...loading, attachLoad: false })
        })
    }

    return (
        <div className="relative top-[4%] px-8 md:px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">
            <h1 className="text-[20px] font-semibold my-[3rem]">Course Details Page</h1>
            <div className='grid grid-cols-12 md:gap-[40px]'>
                {loading.initialLoad ? <center className='col-span-12 md:col-span-8'><Scale color="black" /> </center> :
                    <div className='col-span-12 md:col-span-8'><>
                        <p className='text-[24px] font-semibold w-fit'>{courseInfo?.name}</p>
                        {courseInfo &&
                            <div className='mt-3 text-[16px] w-fit'>
                                {parse(courseInfo?.description)}
                            </div>
                        }
                    </>
                    </div>
                }
                <form className='bg-white shadow-lg rounded-lg p-4 col-span-12 md:col-span-4 h-fit shrink-0 mt-[40px] md:mt-0' onSubmit={handleAdduser}>
                    <p className='text-[16px] mb-4'>Attach {userProfile?.user?.userRole === "Lecturer" ? "Student" : "Lecturer"} to Course</p>
                    <SelectUI placeholder={`Select or search for a ${userProfile?.user?.userRole === "Lecturer" ? "student" : "lecturer"}`} customStyle={"!border text-[14px] mb-4"} isMulti={false} options={[...users]} handleChange={setSelectedUserType} defaultValue={selectedUserType} />
                    <Buttons text={loading.attachLoad ? "Loading" : `Attach ${userProfile?.user?.userRole === "Lecturer" ? "Student" : "Lecturer"}`} variant={loading.attachLoad ? "disabled" : "primary"} />
                </form>
            </div>
        </div>
    )
}

export default SingleCourse