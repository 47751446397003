import React, { useEffect, useState } from "react";
import Table from "../../../CustomUIElements/Table/Table";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
// import { FormInputFields } from "../../GetStarted/FormInputFields";
// import Inputs from "../../../CustomUIElements/Inputs/Inputs";
// import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { LiaTimesSolid } from "react-icons/lia";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { HiOutlineSearch } from "react-icons/hi";
// import { useMutation } from "react-query";
// import { toast } from "react-toastify";
// import useAuthStore from "../../../store/authStore";
// import { useNavigate } from "react-router-dom";
// import { deleteInstitution } from "../../../utils/deleteInvestor";
// import { activateEntityStatus } from "../../../utils/activateEntityStatus";
// import AddInstitutionForm from "./AddInstitutionForm";
// import { ChromePicker } from "react-color";
// import { uploadFile } from "../../../utils/uploadPicture";
// import apiClient from "../../../libs/http-common";
// import { fetchSettingsById } from "../../../utils/fetchSettings";
// import useWindowSize from "../../../hooks/useWindowSize";

function AllManualsTable({ data, loading, refetch, selectedRows, setSelectedRows, searchQuery }) {
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  const [rowData, setRowData] = useState(null);
//   const [showCustomize, setShowCustomize] = useState(false)
//   const { removeUser } = useAuthStore();
//   const { width } = useWindowSize()
//   const navigate = useNavigate()
//   const [setting, setSetting] = useState(null)
//   const [logoUrl, setLogoUrl] = useState(null)
//   const [logo, setLogo] = useState(null)
//   const [color, setColor] = useState("")
//   const { mutate, isLoading: deletingUser } = useMutation(() => deleteInstitution(rowData?.id), {
//     onSuccess: ({ data }) => {
//       refetch()
//       toast.success("Operation successful")
//       setOpenModal({ modalName: "", showModal: false })
//     },
//     onError: ({ response }) => {
//       toast.error(`${response?.data?.status}`);
//       if (response?.data?.status === 401) {
//         removeUser()
//         navigate("/ogin")
//       }
//     },
//   })
//   const { mutate: activateUser, isLoading: activatingUser } = useMutation(() => activateEntityStatus("LearningInstitutions", rowData?.id), {
//     onSuccess: ({ data }) => {
//       refetch()
//       toast.success("Operation successful")
//       setOpenModal({ modalName: "", showModal: false })
//     },
//     onError: ({ response }) => {
//       toast.error(`${response?.data?.status}`);
//       if (response?.data?.status === 401) {
//         removeUser()
//         navigate("/ogin")
//       }
//     },
//   })

//   useEffect(() => {
//     if (rowData) {
//       fetchSettingsById({ id: rowData.id }).then((res) => {
//         // console.log(res.data)
//         setSetting(res.data.data)
//       })
//     }
//   }, [rowData])

  return (
    <>
    <div className="flex flex-col lg:flex-row justify-between items-center  mb-[2rem] gap-y-[20px] lg:gap-y-0">
                    <div className="flex flex-col lg:flex-row items-center gap-4">
                        <h1 className="text-[20px] font-semibold">Manage Manuals</h1>
                        <div className="relative">
                            <input
                                className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
                                placeholder="Search"
                                value={searchQuery}
                                // onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <span className="absolute top-[14px] left-[15px] text-[20px]">
                                <HiOutlineSearch />
                            </span>
                        </div>
                    </div>
                    <div className="w-fit">
                        <Buttons
                            type="button"
                            text="Add New Funnel"
                            variant="primary"
                            // handleClick={() => setOpenModal(true)}
                        />
                    </div>
                </div>
      <Table
        header={[
          { name: "Title", identifier: "name" },
          { name: "Description", identifier: "firstName" },
          { name: "Date", identifier: "contactNumber" },
        ]}
        data={[1,2,3]}
        options={{
          variant: "primary",
          tableStyle: "striped-even",
          allowActions: true,
          allowSorting: false,
          actions: {
            edit: true,
            delete: true,
            view: true,
            hideActivate:true,
          },
          showPagination: true,
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        loading={loading}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchQuery={searchQuery}
      />
    
    </>
  );
}

export default AllManualsTable;

