import React, { useEffect } from 'react'
import { Buttons } from '../../../CustomUIElements/Buttons/Buttons'
import Inputs from '../../../CustomUIElements/Inputs/Inputs'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { updateUserInfo } from '../../../utils/userAccountFn'

function AddInvestorForm({ formValues, setFormValues, isLoading, rowData, setOpenModal, refetch, mode }) {
  useEffect(() => {
    setFormValues({
      firstName: rowData?.firstName,
      lastName: rowData?.lastName,
      email: rowData?.email,
      phoneNumber: rowData?.phoneNumber,
      organisationName: rowData?.organisationName,
      id: rowData?.id
    })
  }, [])

  const { mutate, isLoading: updating } = useMutation(updateUserInfo, {
    onSuccess: ({ data }) => {
      // saveUserProfile(data.data);
      setOpenModal()
      toast.success(`${data.status}`)
      setFormValues({})
      refetch()
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({
      organisationName: formValues?.organisationName,
      firstName: formValues?.firstName,
      lastName: formValues?.lastName,
      emailAddress: formValues?.email,
      mobileNumber: formValues?.phoneNumber,
      userId: formValues?.id,
      website: formValues?.website,
      isInvestor: true
    });
  };
  return (
    <form className="space-y-[1.6rem]" onSubmit={handleSubmit}>
      {FormInputFields.map(({ name, label, type, isRequired }) => (
        <Inputs
          key={name}
          type={type}
          label={label}
          name={name}
          isRequired={isRequired}
          value={formValues[name]}
          handleChange={(e) =>
            setFormValues({
              ...formValues,
              [e.target.name]: e.target.value,
            })
          }
          readOnly={mode === "view"}
        />
      ))}
      {mode !== "view" &&
        <div className="!mt-[3rem]">
          <Buttons
            variant={
              formValues?.firstName &&
                formValues?.lastName &&
                formValues?.email &&
                formValues?.phoneNumber &&
                !isLoading
                ? "primary"
                : "disabled"
            }
            type="submit"
            text={updating ? "Submitting..." : "Submit"}
          />
        </div>
      }
    </form>
  )
}

export default AddInvestorForm

export const FormInputFields = [
  {
    label: "First Name",
    isRequired: true,
    type: "text",
    name: "firstName",
  },
  {
    label: "Last Name",
    isRequired: true,
    type: "text",
    name: "lastName",
  },
  {
    label: "Phone Number",
    isRequired: true,
    type: "number",
    name: "phoneNumber",
  },
  {
    label: "Email Address",
    isRequired: true,
    type: "email",
    name: "email",
  },
  {
    label: "Organisation Name",
    isRequired: false,
    type: "text",
    name: "organisationName",
  },
  {
    label: "Website URL",
    isRequired: false,
    type: "text",
    name: "website",
  },
]