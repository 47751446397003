import React from "react";
import InstitutionsAdministration from "../../Components/AdminDashboard/Institution/Index";
import AllManualsTable from "../../Components/AdminDashboard/manuals";

function ManageManuals() {
    return (
        <div className="relative top-[8%] px-8 lg:px-16 py-8 bg-[#f1f1f1]">
            {/* <InstitutionsAdministration /> */}
            {/* manuals */}
            <AllManualsTable/>
        </div>
    );
}

export default ManageManuals;
