import React, { useState } from "react";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { FiTrash2 } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import { CgUnavailable } from "react-icons/cg";
import Scale from "../Loaders/ScaleLoader";
import "./Table.css"
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext";
// import Loader from "./Loader";

function Table({
  header,
  data,
  options,
  openModal,
  setOpenModal,
  rowData,
  setRowData,
  searchQuery,
  loading,
  selectedRows,
  setSelectedRows,
  path
}) {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc"); // Default to ascending order
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default number of items per page
  // const [selectedRows, setSelectedRows] = useState([])
  const navigate = useNavigate()
  const { theme, themeStyles } = useTheme();

  const handleSort = (column) => {
    if (sortBy === column) {
      if (sortOrder === "asc") {
        setSortOrder("desc");
      } else if (sortOrder === "desc") {
        setSortBy(null);
        setSortOrder("asc");
      } else {
        setSortOrder("asc");
      }
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  // Filter data based on search query
  const filteredData = searchQuery
    ? data?.filter((item) =>
      Object.values(item)?.some((value) =>
        value
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase())
      )
    )
    : data || [];

  const sortedData = filteredData?.slice()?.sort((a, b) => {
    const columnA = a[sortBy];
    const columnB = b[sortBy];

    // Check if column values are integers
    const isInteger = Number.isInteger(columnA) && Number.isInteger(columnB);

    if (sortOrder === "asc") {
      if (isInteger) {
        return columnA - columnB; // Directly compare integers
      } else {
        return columnA?.toString()?.localeCompare(columnB?.toString());
      }
    } else {
      if (isInteger) {
        return columnB - columnA; // Directly compare integers
      } else {
        return columnB?.toString()?.localeCompare(columnA?.toString());
      }
    }
  });

  const handleRowClick = (row) => {
    // Check if the row is already selected
    const rowExists = selectedRows?.find((r) => r?.id === row?.id)
    console.log('row already exists', rowExists);
    if (rowExists) {
      const removeSelectedRow = selectedRows?.filter((r) => r?.id !== row?.id)
      setSelectedRows(removeSelectedRow)
    } else {
      setSelectedRows([...selectedRows, row])
    }

  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // console.log('selectedRows', selectedRows);

  return (
    <div className="w-full overflow-x-auto text-[14px]">
      <table className="table-auto w-full border-collapse">
        <thead
          className={`${options?.variant === "primary" ? "tablePrimaryBg" : "bg-white text-black"
            }`}
          style={{ backgroundColor: theme.backgroundColor }}
        >
          <tr>
            <th className="p-3 text-left">#</th>
            {header?.map((item, index) => (
              <th
                key={index}
                className="p-3 text-left font-semibold cursor-pointer whitespace-nowrap"
                onClick={() => options?.allowSorting && handleSort(item.identifier)}
              >
                {item.name}
                {sortBy === item.identifier && (sortOrder === "asc" ? " ↑" : " ↓")}
              </th>
            ))}
            {options?.allowActions && <th className="p-3">Actions</th>}
          </tr>
        </thead>
        <tbody className="text-[14px]">
        {currentItems?.length > 0 ? (
  currentItems.map((rowData, rowIndex) => (
    <tr
      key={rowIndex}
      className={` hover:bg-gray-300 ${options?.tableStyle === "striped-even"
        ? "even:bg-gray-100"
        : "odd:bg-gray-100"
        } border-b`}
    >
      <td className="p-3 ">{rowIndex + 1}</td>
      {header.map((column, colIndex) => {
        return <td key={colIndex} className={` cursor-pointer  ${column?.identifier == "hasAccepted"
          ? rowData[column.identifier] == true
            ? "text-green-700"
            : rowData[column.identifier] == "Failed" || rowData[column.identifier] == "Rejected"
              ? "bg-red-500 text-white text-center"
              : rowData[column.identifier] == false
                ? "bg-yellow-400 text-white text-center"
                : "text-text"
          : "text-text"
          } p-3  truncate max-w-xs`} onClick={() => path && navigate(`${path}/${rowData?.id}`)}>{column?.identifier === "hasAccepted" ? rowData[column.identifier] === true ? "Invite Accepted" : "Pending Invite" : rowData[column.identifier] ?? "N/A"}</td>;
      })}
      {options?.allowActions && (
        <td className="p-3">
          <div className="flex items-center gap-2 flex-wrap justify-start">
            {options?.actions?.edit && (
              <button
                className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-100"
                onClick={() => {
                  setOpenModal &&
                    setOpenModal({
                      modalName: "edit",
                      showModal: true,
                    });
                  setRowData && setRowData(rowData);
                }}
              >
                <CiEdit size={16} />
              </button>
            )}
            {!options?.actions?.hideActivate && rowData?.isActive !== null && rowData?.isActive ? <span
              className="w-[2rem] h-[2rem] flex justify-center items-center rounded-full bg-white cursor-pointer"
              onClick={() => {
                setOpenModal &&
                  setOpenModal({
                    modalName: "disable",
                    showModal: true,
                  });
                setRowData && setRowData(rowData);
              }}
            >
              <CgUnavailable size={14} color="green" />
            </span> : !options?.actions?.hideActivate && <span
              className="w-[2rem] h-[2rem] flex justify-center items-center rounded-full bg-white cursor-pointer"
              onClick={() => {
                setOpenModal &&
                  setOpenModal({
                    modalName: "enable",
                    showModal: true,
                  });
                setRowData && setRowData(rowData);
              }}
            >
              <CgUnavailable size={14} color="red" />
            </span>}
            {options?.actions?.delete && (
              <button
                className="w-8 h-8 flex items-center justify-center rounded-full bg-red-100"
                onClick={() => {
                  setOpenModal &&
                    setOpenModal({
                      modalName: "delete",
                      showModal: true,
                    });
                  setRowData && setRowData(rowData);
                }}
              >
                <FiTrash2 size={14} color="red" />
              </button>
            )}
          </div>
        </td>
      )}
    </tr>
  ))
) : (
  <tr>
    <td colSpan={header?.length + 1}>
      <div className="h-[200px] flex justify-center items-center w-full">
        {loading ? <Scale color={theme.backgroundColor} /> : "No data found"}
      </div>
    </td>
  </tr>
)}

        </tbody>
      </table>
      {options?.showPagination && (
        <div className="flex flex-wrap justify-between items-center mt-4 gap-2">
          <div className="flex items-center gap-2">
            <label className="">Items Per Page</label>
            <select
              className="bg-gray-100 p-2 rounded-md "
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(e.target.value)}
            >
              {[10, 20, 30, 40, 50].map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          {data?.length > itemsPerPage && (
            <div className="flex items-center gap-2 overflow-x-auto">
              {Array.from({ length: Math.ceil(data?.length / itemsPerPage) }).map(
                (_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`${currentPage === index + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-100"
                      } px-3 py-1 rounded `}
                  >
                    {index + 1}
                  </button>
                )
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );

}

export default Table;