import React, { useState } from 'react'
import { HiOutlineSearch } from 'react-icons/hi'
import { IoIosRadioButtonOff } from "react-icons/io";
import { IoIosRadioButtonOn } from "react-icons/io";
import { Buttons } from '../../CustomUIElements/Buttons/Buttons';
import Table from '../../CustomUIElements/Table/Table';
import ModalContainer from '../../CustomUIElements/Modal/ModalContainer';
import { LiaTimesSolid } from 'react-icons/lia';
import Inputs from '../../CustomUIElements/Inputs/Inputs';
import { useMutation, useQuery } from 'react-query';
import { fetchInvites, inviteUser } from '../../utils/inviteUser';
import { toast } from 'react-toastify';
import useAuthStore from '../../store/authStore';
import ConfirmationModal from '../../CustomUIElements/Modal/ConfirmationModal';
import { deleteInvites } from '../../utils/deleteInvites';
import SelectUI from '../../CustomUIElements/Inputs/Select';
import { fetchCourses } from '../../utils/courseFunctions';
import { getMyCourses } from '../../utils/students';
import { useHandleApiError } from '../../hooks/useHandleApiError';



function MyInvites() {
    const [searchQuery, setSearchQuery] = useState("");
    const [openFilters, setOpenFilters] = useState(false);
    const [openInviteModal, setOpenInviteModal] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("")
    const { userProfile, removeUser } = useAuthStore()
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [selectedRows, setSelectedRows] = useState(null);
    // const [loading, setLoading] = useState([)
    const currentRoute = typeof window !== "undefined" ? window.location.pathname : "";
    const { handleError } = useHandleApiError(currentRoute);
    const [formValues, setFormValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        userRole: userProfile?.user?.userRole === "Lecturer" ? "Student" : "",
        learningInstitutionId: "",
        courseName: ""
    })
    const [invites, setInvites] = useState([])
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [selectedUserType, setSelectedUserType] = useState(null)
    const [openModal, setOpenModal] = useState({
        modalName: "",
        showModal: false,
    });
    const [courses, setCourses] = useState([])

    const { isLoading: loadingCourses } = useQuery(
        ['fetchCourses', userProfile?.user?.learningInstitutionId], // Query key
        () => fetchCourses(userProfile?.user?.learningInstitutionId), // Fetch function
        {
            enabled: userProfile?.user?.userRole === "LearningInstitution", // Only run if ID exists
            refetchOnWindowFocus: false,
            retry: false,
            refetchInterval: false,
            onSuccess: ({ data }) => {
                let d = []
                const res = data?.data
                res?.forEach((dd, i) => {
                    if (dd?.name.trim() !== "") {
                        let obj = { value: dd?.id, label: dd?.name }
                        d.push(obj)
                    }
                })
                console.log(d)
                setCourses(d);
            },
            onError: (error) => {
                if (error?.response?.status === 401) {
                    // removeUser();
                    // window.location.href = "/login";
                     handleError(error)
                }
            }
        }
    );
    const { } = useQuery("getMyCourses", getMyCourses, {
        enabled: userProfile?.user?.userRole === "Lecturer",
        refetchOnWindowFocus: true,
        retry: false,
        refetchInterval: false,
        onSuccess: ({ data }) => {
            let d = []
            const res = data?.data?.courses
            res?.forEach((dd, i) => {
                if (dd?.name.trim() !== "") {
                    let obj = { value: dd?.id, label: dd?.name }
                    d.push(obj)
                }
            })
            console.log(d)
            setCourses(d);
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                // removeUser()
                // window.location.href = "/login"
                handleError(response)
            }
        }
    })
    const { isLoading: loadingInvites, isRefetching, refetch } = useQuery("fetchInvites", fetchInvites, {
        enabled: true,
        refetchOnWindowFocus: true,
        onSuccess: ({ data }) => {
            // console.log(data.data)
            setInvites(data?.data?.invites)
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                // removeUser()
                // window.location.href = "/login"
                handleError(response)
            }
        }
    })

    const { mutate: deleteInvite, isLoading: deleting } = useMutation(() => deleteInvites(rowData?.id), {
        onSuccess: ({ data }) => {
            refetch()
            toast.success("Invites deleted")
            setOpenModal({ modalName: "", showModal: false })
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
            if (response?.data?.status === 401) {
                // removeUser()
                handleError(response)
                // navigate("/ogin")
            }
        },
    });

    const { mutate, isLoading: addingInvite } = useMutation(inviteUser, {
        onSuccess: ({ data }) => {
            setOpenInviteModal(false)
            toast.success(`Invite sent successfully`)
            setFormValues({})
            refetch()
            setOpenModal({ modalName: "", showModal: false })
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        mutate({
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            emailAddress: formValues.email,
            userRole: selectedUserType?.value,
            courseId: selectedCourse?.value,
            hasAccepted: false,
            learningInstitutionId: userProfile?.user?.learningInstitutionId,
            dateAcceptedOrRejected: new Date().toISOString(),
        });
    };

    const handleResend = () => {
        // e.preventDefault();
        mutate({
            firstName: rowData.firstName,
            lastName: rowData.lastName,
            emailAddress: rowData.emailAddress,
            userRole: rowData?.userRole,
            courseId: rowData?.courseId,
            hasAccepted: false,
            learningInstitutionId: rowData.learningInstitutionId,
            dateAcceptedOrRejected: rowData?.dateAcceptedOrRejected,
        });
        // setOpenModal({ modalName: "", showModal: false })};
    };

    // console.log({ selectedCourse, selectedUserType })

    return (
        <div className="relative top-[8%] px-8 md:px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">

            <h1 className="text-[20px] font-semibold my-[3rem]">My Invites</h1>
            <div className='flex flex-col lg:flex-row justify-between items-center mb-8 gap-4'>
                <div className="relative">
                    <input
                        className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="absolute top-[14px] left-[15px] text-[20px]">
                        <HiOutlineSearch />
                    </span>
                </div>
                <div className='flex gap-[20px]'>
                    <div className='w-[150px]'>
                        <Buttons text="Invite Users" variant="primary" handleClick={() => setOpenInviteModal(true)} />
                    </div>
                    <div className='w-[150px] relative'>
                        <Buttons text="Filter by" variant="cancel" handleClick={() => setOpenFilters((prev) => !prev)} />
                        {openFilters &&
                            <>
                                <div className='absolute bg-white rounded-[8px] p-4 w-[150px]'>
                                    <div className='flex justify-between text-[12px] items-center border-b pb-2 mb-1 cursor-pointer' onClick={() => setSelectedFilter("all")}>
                                        <p>All</p>
                                        {selectedFilter == "all" ? <IoIosRadioButtonOn size={16} /> :
                                            <IoIosRadioButtonOff size={16} />
                                        }
                                    </div>
                                    <div className='flex justify-between text-[12px] items-center border-b pb-2 mb-1 cursor-pointer' onClick={() => setSelectedFilter("pending")}>
                                        <p>Pending</p>
                                        {selectedFilter == "pending" ? <IoIosRadioButtonOn size={16} /> :
                                            <IoIosRadioButtonOff size={16} />
                                        }
                                    </div>
                                    <div className='flex justify-between text-[12px] items-center cursor-pointer' onClick={() => setSelectedFilter("rejected")}>
                                        <p>Rejected</p>
                                        {selectedFilter == "rejected" ? <IoIosRadioButtonOn size={16} /> :
                                            <IoIosRadioButtonOff size={16} />
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Table
                header={
                    [
                        { name: "First Name", identifier: "firstName" },
                        { name: "Last Name", identifier: "lastName" },
                        { name: "Email", identifier: "emailAddress" },
                        { name: "Role", identifier: "userRole" },
                        { name: "Status", identifier: "hasAccepted" }
                    ]}
                options={{
                    variant: "primary",
                    tableStyle: "striped-even",
                    allowActions: true,
                    allowSorting: true,
                    showPagination: true,
                    actions: {
                        delete: true,
                        hideActivate: true,
                        resend:true
                    }
                }}
                data={invites}
                openModal={openModal}
                setOpenModal={setOpenModal}
                searchQuery={searchQuery}
                rowData={rowData}
                setRowData={setRowData}
                loading={loadingInvites}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
            />

            <ConfirmationModal
                showModal={openModal?.modalName === "disable" && openModal?.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to de-activate this invites?"

            />
            <ConfirmationModal
                showModal={openModal?.modalName === "enable" && openModal?.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to activate this invites?"

            />

            {openInviteModal && (
                <ModalContainer height="auto">
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem] mb-4">
                            <h3 className="">Invite User</h3>
                            <LiaTimesSolid
                                onClick={() =>
                                    setOpenInviteModal(false)
                                }
                                className="cursor-pointer"
                            />
                        </div>
                        <form action="" className='space-y-4' onSubmit={handleSubmit}>
                            <Inputs type="text" label="First Name" minLength={1} vlaue={formValues?.firstName} handleChange={(e) => setFormValues((prev) => ({ ...prev, firstName: e.target.value }))} />
                            <Inputs type="text" label="Last Name" minLength={1} vlaue={formValues?.lastName} handleChange={(e) => setFormValues((prev) => ({ ...prev, lastName: e.target.value }))} />
                            <SelectUI customStyle={`bg-[#F7F7F7] text-[16px] border-none py-[5px] rounded-2xl text-black`} placeholder="Select course..." options={courses} isMulti={false} name="courses" handleChange={setSelectedCourse} />
                            <Inputs type="email" label="Email Address" minLength={1} vlaue={formValues?.email} handleChange={(e) => setFormValues((prev) => ({ ...prev, email: e.target.value }))} />
                            <SelectUI customStyle={`bg-[#F7F7F7] text-[16px] border-none py-[5px] rounded-2xl`} placeholder="Select user type..." options={userProfile?.user?.userRole === "Lecturer" ? [{ label: "Student", value: "Student" }] : [{ label: "Lecturer", value: "Lecturer" }, { label: "Student", value: "Student" }]} isMulti={false} name="user types" handleChange={setSelectedUserType} />
                            <Buttons text={addingInvite ? "loading..." : "Invite User"} variant={addingInvite ? "disabled" : "primary"} />
                        </form>
                    </div>
                </ModalContainer>
            )}

            {openModal.showModal && openModal.modalName === "delete" && (
                <ModalContainer height="auto">
                    <ConfirmationModal
                        showModal={openModal.modalName === "delete" && openModal.showModal}
                        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                        message="Are you sure you want to delete this Invites?"
                        onConfirm={() => deleteInvite()}
                        loading={deleting}
                    />
                </ModalContainer>
            )}

        {openModal.showModal && openModal.modalName === "resend" && (
                <ModalContainer height="auto">
                    <ConfirmationModal
                        showModal={openModal.modalName === "resend" && openModal.showModal}
                        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                        message="Are you sure you want to resend this invite?"
                        onConfirm={() => handleResend()}
                        loading={addingInvite}
                    />
                </ModalContainer>
            )}


        </div>
    )
}

export default MyInvites