import axios from "axios";

// Create the Axios instance without a static `baseURL`
const apiClient = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

// Add an interceptor to set the baseURL dynamically
apiClient.interceptors.request.use((config) => {
  const isProduction = process.env.NODE_ENV === "production";
  const isWeb = window.location.origin.includes("web");
  const devAPI = window.localStorage.getItem("dev_api_link");
  const prodAPI = window.localStorage.getItem("prod_api_link");
  const isLocalhost = window.location.origin.includes("localhost");

  // Dynamically set the baseURL before each request
  config.baseURL = isProduction
    ? isWeb
      ? `${devAPI}/v1`
      : `${prodAPI}/v1`
    : isLocalhost
    ? `${devAPI}/v1`
    : `${prodAPI}/v1`;
    
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default apiClient;
