import React, { useEffect, useState } from 'react'
import { HiOutlineSearch } from 'react-icons/hi';
import useAuthStore from '../../store/authStore';
import { useMutation, useQuery } from 'react-query';
import { addGroup, deleteGroup, getGroups, getGroupsByCourseId, getMyCourses, updateGroup } from '../../utils/students';
import { Buttons } from '../../CustomUIElements/Buttons/Buttons';
import ModalContainer from '../../CustomUIElements/Modal/ModalContainer';
import Inputs from '../../CustomUIElements/Inputs/Inputs';
import { LiaTimesSolid } from 'react-icons/lia';
import { toast } from 'react-toastify';
import Table from '../../CustomUIElements/Table/Table';
import { createGroupChat, deleteGroupChat, updateGroupDetails } from '../../utils/chatFunctions';
import ConfirmationModal from '../../CustomUIElements/Modal/ConfirmationModal';
import ChatPopup from '../ChatBox/ChatPopup';
import { MdClose, MdOutlineMessage } from 'react-icons/md';
import SelectUI from '../../CustomUIElements/Inputs/Select';
import { fetchCourses } from '../../utils/courseFunctions';
import { useTheme } from '../../context/ThemeContext';
import useWindowSize from '../../hooks/useWindowSize';

function Groups({ src }) {
    const [searchQuery, setSearchQuery] = useState("");
    const [groups, setGroups] = useState([])
    const { userProfile, removeUser } = useAuthStore()
    const [openAddModal, setOpenAddModal] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openChat, setOpenChat] = useState(false);
    const [formValues, setFormValues] = useState({
        name: "",
        desc: ""
    })
    const {theme, themeStyles} = useTheme()
    const [rowData, setRowData] = useState(null)
    const [courses, setCourses] = useState([])
    const [selectedCourse, setSelectedCourse] = useState(null)
    const {width} = useWindowSize()
    const { isLoading: loadingMyCourse } = useQuery("getMyCourses", getMyCourses, {
        enabled: true,
        refetchOnWindowFocus: true,
        retry: false,
        refetchInterval: false,
        onSuccess: ({ data }) => {
            // console.log(data.data)
            let d = []
            const res = data?.data?.courses
            res?.forEach((dd, i) => {
                if (dd?.name.trim() !== "") {
                    let obj = { value: dd?.id, label: dd?.name, courseCode: dd?.courseCode }
                    d.push(obj)
                }
            })
            console.log(d)
            setCourses(d);
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        }
    })


    const { mutate: getGroupByCourse, refetch, isLoading: loadingGroups } = useMutation(getGroupsByCourseId, {
        onSuccess: ({ data }) => {
            console.log("here", data.data)
            setLoading(false)
            setGroups(data?.data)
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        },
    });
    const { mutate } = useMutation(addGroup, {
        onSuccess: ({ data }) => {
            setOpenAddModal(false)
            console.log(data?.data)
            toast.success(`Group added successfully`)
            setFormValues({})
            getGroupByCourse({
                id: selectedCourse.value
            })
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
        },
    });
    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        let groupInfo = {
            name: formValues?.name,
            description: formValues?.desc,
            learningInstitutionId: userProfile?.user?.learningInstitutionId
        }
        let creatorInfo = {
            id: userProfile?.user?.id,
            firstName: userProfile?.user?.firstName,
            lastName: userProfile?.user?.lastName
        }
        let courseInfo = {
            courseId: selectedCourse?.value,
            courseName: selectedCourse?.label,
            courseCode: selectedCourse?.courseCode
        }

        await createGroupChat(groupInfo, creatorInfo, courseInfo).then((res) => {
            console.log(res)
            mutate({
                name: formValues.name,
                description: formValues.desc,
                reference: res,
                learningInstitutionId: userProfile?.user?.learningInstitutionId,
                courseId: selectedCourse?.value
            });
        }).catch(() => {
            setLoading(false)
        })
    };

    const handleUpdateSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        let groupInfo = {
            groupId: rowData?.reference,
            name: formValues?.name,
            description: formValues?.desc,
            learningInstitutionId: rowData?.learningInstitutionId,
        }
        await updateGroupDetails(groupInfo).then((res) => {
            console.log(res)
            updateGroup({
                name: formValues.name,
                description: formValues.desc,
                id: rowData?.id,
                learningInstitutionId: rowData?.learningInstitutionId,
                reference: rowData?.reference,
            }).then((res) => {
                toast.success("Group updated successfully")
                setOpenModal({})
                refetch()
                setRowData(null)
                setLoading(false)
            });
        }).catch(() => {
            setLoading(false)
        })
    };

    const handleDelete = async () => {
        setLoading(true)
        const res = await deleteGroupChat(rowData?.reference)
        if (res) {
            await deleteGroup(rowData?.id).then((res) => {
                refetch()
                // setOpenModal({})
                setLoading(false)
                setOpenModal({ modalName: "", showModal: false })
            })
        }
    }

    useEffect(() => {
        if (selectedCourse) {
            getGroupByCourse({
                id: selectedCourse.value
            })
        }
    }, [selectedCourse])

    useEffect(() => {
        if (userProfile?.user?.userRole === "Student") {
            const studCourses = userProfile?.user?.studentView?.courses
            let d = []
            studCourses?.forEach((dd, i) => {
                if (dd?.name.trim() !== "") {
                    let obj = { value: dd?.id, label: dd?.name }
                    d.push(obj)
                }
            })
            // console.log(d)
            setCourses(d)
        }
    }, [userProfile])

    useEffect(() => {
        // console.log(rowData)
        if (rowData) {
            setFormValues({
                name: rowData?.name,
                desc: rowData?.description
            })
        }
    }, [rowData])
    return (
        <div className="relative top-[8%] px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">
            <h1 className="text-[20px] font-semibold my-[3rem]">My Groups</h1>
            <div className='flex flex-col md:flex-row justify-between items-center mb-8 gap-4'>
                <div className='flex flex-col md:flex-row items-center gap-[20px]'>
                    <div className="relative">
                        <input
                            className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <span className="absolute top-[14px] left-[15px] text-[20px]">
                            <HiOutlineSearch />
                        </span>
                    </div>
                    <div>
                        <SelectUI placeholder="Choose a course" customStyle={"w-full md:w-[200px] h-[45px] border rounded-[8px] bg-white text-[16px]"} options={courses} handleChange={setSelectedCourse} />
                    </div>
                </div>
                {src !== "student" &&
                    <div className='w-[150px]'>
                        <Buttons text="Add Group" variant="primary" handleClick={() => setOpenAddModal(true)} />
                    </div>
                }
            </div>
            <p className='text-red-400 text-[15px]'><i>Instructions: Click on a group name to see more information about a group</i></p>
            <Table
                header={
                    [
                        { name: "Group Name", identifier: "name" },
                        { name: "Group Description", identifier: "description" },
                    ]}
                options={{
                    variant: "primary",
                    tableStyle: "striped-even",
                    allowActions: userProfile?.user?.userRole !== "Student",
                    allowSorting: true,
                    showPagination: true,
                    actions: {
                        delete: userProfile?.user?.userRole !== "Student",
                        edit: userProfile?.user?.userRole !== "Student"

                    }
                }}
                data={[
                    ...groups
                ]}
                loading={loadingGroups}
                path={userProfile?.user?.userRole !== "Student" ? `/lecturer/dashboard/courses/${selectedCourse?.value}/groups` : `/student/dashboard/group-ideas/course/${selectedCourse?.value}/group`}
                rowData={rowData}
                setRowData={setRowData}
                searchQuery={searchQuery}
                openModal={openModal}
                setOpenModal={setOpenModal}
            />
            <ConfirmationModal
                showModal={openModal?.modalName === "enable" && openModal?.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to activate this Group?"
            //    onConfirm={() => mutate()}
            />
            <ConfirmationModal
                showModal={openModal?.modalName === "disable" && openModal?.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to de-activate this Group?"

            />


            {openModal.showModal && openModal.modalName === "delete" && (
                <ModalContainer height="auto">
                    <ConfirmationModal
                        showModal={openModal.modalName === "delete" && openModal.showModal}
                        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                        message="Are you sure you want to delete this Group?"
                        onConfirm={handleDelete}
                        loading={loading}

                    />
                </ModalContainer>
            )}
            {openAddModal && (
                <ModalContainer width={width < 768 ? "95vw" :"500px"} height={"auto"}>
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem] mb-4">
                            <h3 className="">Add Group</h3>
                            <LiaTimesSolid
                                onClick={() =>
                                    setOpenAddModal(false)
                                }
                                className="cursor-pointer"
                            />
                        </div>
                        <form action="" className='space-y-8' onSubmit={handleSubmit}>
                            <Inputs type="text" label="Group Name" isRequired={true} value={formValues.name} handleChange={(e) => setFormValues((prev) => ({ ...prev, name: e.target.value }))} />
                            <Inputs type="text" label="Group Description" isRequired={true} value={formValues.desc} handleChange={(e) => setFormValues((prev) => ({ ...prev, desc: e.target.value }))} />
                            {selectedCourse === null &&
                                <SelectUI customStyle={`bg-[#F7F7F7] text-[16px] border-none py-[5px] rounded-2xl text-black`} placeholder="Select course..." options={courses} isMulti={false} name="courses" handleChange={setSelectedCourse} />
                            }
                            <Buttons text={loading ? "loading..." : "Add Group"} variant={loading ? "disabled" : "primary"} />
                        </form>
                    </div>
                </ModalContainer>
            )}
            {openModal?.modalName === "edit" && openModal?.showModal &&
                <ModalContainer width={width < 768 ? "95vw" :"500px"} height={"auto"}>
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem] mb-4">
                            <h3 className="">Edit Group</h3>
                            <LiaTimesSolid
                                onClick={() =>
                                    setOpenModal({})
                                }
                                className="cursor-pointer"
                            />
                        </div>
                        <form action="" className='space-y-8' onSubmit={handleUpdateSubmit}>
                            <Inputs type="text" label="Group Name" isRequired={true} value={formValues.name} handleChange={(e) => setFormValues((prev) => ({ ...prev, name: e.target.value }))} />
                            <Inputs type="text" label="Group Description" isRequired={true} value={formValues.desc} handleChange={(e) => setFormValues((prev) => ({ ...prev, desc: e.target.value }))} />
                            {selectedCourse === null &&
                                <SelectUI customStyle={`bg-[#F7F7F7] text-[16px] border-none py-[5px] rounded-2xl text-black`} placeholder="Select course..." options={courses} isMulti={false} name="courses" handleChange={setSelectedCourse} />
                            }
                            <Buttons text={loading ? "loading..." : "Update Group"} variant={loading ? "disabled" : "primary"} />
                        </form>
                    </div>
                </ModalContainer>
            }
            <ConfirmationModal
                message="Are you sure you want to delete this group?"
                showModal={openModal?.showModal && openModal.modalName === "delete"}
                onConfirm={handleDelete}
                onCancel={() => setOpenModal({})}
            />
            {openChat && <div className='fixed bottom-[90px] right-[30px]'>
                <ChatPopup />
            </div>}
            <div className='fixed bottom-[30px] right-[30px]'>
                <div className=' w-[50px] h-[50px] rounded-full flex justify-center items-center cursor-pointer' style={{ backgroundColor: theme.backgroundColor }} onClick={() => setOpenChat((prev) => !prev)}>
                    {openChat ? <MdClose color='white' size={20} /> : <MdOutlineMessage color='white' size={20} />}
                </div>
            </div>
        </div>
    )
}

export default Groups