import useAuthStore from "../store/authStore";

export const useHandleApiError = (currentRoute) => {

  const { userProfile, removeUser } = useAuthStore()
  const handleError = (error) => {
    if (error?.response?.status === 401) {
      // Use window.location.href for redirection
      window.location.href = `/login?route=${encodeURIComponent(currentRoute)}`;
      removeUser()
    } else if(error.status === 400){
      window.location.href = `/login?route=${encodeURIComponent(currentRoute)}`;
      removeUser()
    }
    else {
      console.error("Unhandled API error:", error);
    }
  };

  return { handleError };
};
