import React, { useState } from "react";
import AllInvestorsTable from "./AllInvestorsTable";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { LiaTimesSolid } from "react-icons/lia";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import { useMutation, useQuery } from "react-query";
import { createUserAccount } from "../../../utils/userAccountFn";
import { toast } from "react-toastify";
import { fetchInvestors } from "../../../utils/fetchInvestors";
import useAuthStore from "../../../store/authStore";
import { HiOutlineSearch } from "react-icons/hi";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
import { deleteInvestor } from "../../../utils/deleteInvestor";
import useWindowSize from "../../../hooks/useWindowSize";

function InvestorsAdministration() {
  const [openModal, setOpenModal] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const { removeUser } = useAuthStore()
  // const [isLoading, setIsLoading] = useState(false);
  const [investors, setInvestors] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const {width} = useWindowSize()
  const { isLoading: loadingInvestors, isRefetching, refetch } = useQuery("fetchInvestors", fetchInvestors, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: ({ data }) => {
      setInvestors(data?.data)
    },
    onError: ({ response }) => {
      if (response?.status === 401) {
        removeUser()
        window.location.href = "/login"
      }
    }
  })
  const { mutate, isLoading } = useMutation(createUserAccount, {
    onSuccess: ({ data }) => {
      // saveUserProfile(data.data);
      setOpenModal(false)
      toast.success(`${data.status}`)
      setFormValues({})
      refetch()
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({
      organisationName: formValues?.organisationName,
      firstName: formValues?.firstName,
      lastName: formValues?.lastName,
      emailAddress: formValues?.email,
      mobileNumber: formValues?.phoneNumber,
      password: formValues?.password,
      confirmPassword: formValues?.confirmPassword,
      website: formValues?.website,
      isInvestor: true,
      subscriptionId: 0,
      userRoleEnum: "Investor"
    });
  };

  const mutation = useMutation(deleteInvestor);

  const handleBulkDelete = async () => {
    try {
      await Promise.all(selectedRows?.map(async (row) => {
        // Call the mutation function with the ID
        await mutation.mutateAsync(row?.id);
      }));
      toast.success("Operation completed successfully");
      refetch();
      setOpenModal({ modalName: "", showModal: false });
    } catch (error) {
      console.error("Bulk delete error:", error);
      toast.error("An error occurred during bulk delete operation");
    }
  };
  const activateMutation = useMutation({
    mutationFn: ({ entityName, id }) => activateEntityStatus(entityName, id)
  })
  const handleBulkActivate = async () => {
    try {
      await Promise.all(selectedRows?.map(async (row) => {
        // Call the mutation function with the ID
        console.log(row?.id)
        await activateMutation.mutateAsync({ entityName: "Investors", id: row?.id });
      }));
      toast.success("Operation completed successfully");
      refetch();
      setOpenModal({ modalName: "", showModal: false });
    } catch (error) {
      console.error("Bulk delete error:", error);
      toast.error("An error occurred during bulk activate operation");
    }
  };

  return (
    <div className="min-h-[85vh]">
      <div className="flex flex-col lg:flex-row justify-between items-center  mb-[2rem] gap-y-[20px] lg:gap-y-0">
        <div className="flex flex-col lg:flex-row items-center gap-4">
          <h1 className="text-[20px] font-semibold">Manage Investors</h1>
          <div className="relative">
            <input
              className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="absolute top-[14px] left-[15px] text-[20px]">
              <HiOutlineSearch />
            </span>
          </div>
        </div>
        <div className="w-fit">
          <Buttons
            type="button"
            text="Add New Investor"
            variant="primary"
            handleClick={() => setOpenModal(true)}
          />
        </div>
      </div>
      {selectedRows?.length > 0 &&
        <div className="flex gap-2 items-center">
          <button className="px-4 py-1 rounded-md bg-red-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "delete", showModal: true })}>Disable</button>
          <button className="px-4 py-1 rounded-md bg-green-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "activate", showModal: true })}>Enable</button>
        </div>
      }
      <div className="mt-4 mb-8">
        <AllInvestorsTable data={investors} loading={loadingInvestors || isRefetching} refetch={refetch} selectedRows={selectedRows} setSelectedRows={setSelectedRows} searchQuery={searchQuery} />
      </div>
      {openModal && (
        <ModalContainer height={width <768 ? "70vh" : "auto"}>
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem]  mb-4">
              <h3 className="">Add New Investor</h3>
              <LiaTimesSolid
                onClick={() => { setOpenModal(false); setFormValues({}) }}
                className="cursor-pointer"
              />
            </div>
            <form className="space-y-[1.6rem]" onSubmit={handleSubmit}>
              {FormInputFields.map(({ name, label, type, isRequired }) => (
                <Inputs
                  key={name}
                  type={type}
                  label={label}
                  name={name}
                  isRequired={isRequired}
                  value={formValues[name]}
                  handleChange={(e) =>
                    setFormValues({
                      ...formValues,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              ))}
              <div className="!mt-[3rem]">
                <Buttons
                  variant={
                    formValues?.firstName &&
                      formValues?.lastName &&
                      formValues?.email &&
                      formValues?.phoneNumber &&
                      formValues?.password &&
                      formValues?.confirmPassword &&
                      formValues?.password === formValues?.confirmPassword &&
                      formValues?.organisationName &&
                      !isLoading
                      ? "primary"
                      : "disabled"
                  }
                  type="submit"
                  text={isLoading ? "Submitting..." : "Submit"}
                />
              </div>
            </form>
          </div>
        </ModalContainer>
      )}
      <ConfirmationModal
        showModal={openModal.modalName === "delete" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to delete these investors?"
        onConfirm={handleBulkDelete}
      // loading={deletingUser}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "activate" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate these investors?"
        onConfirm={handleBulkActivate}
      // loading={deletingUser}
      />
    </div>
  );
}

export default InvestorsAdministration;

export const FormInputFields = [
  {
    label: "First Name",
    isRequired: true,
    type: "text",
    name: "firstName",
  },
  {
    label: "Last Name",
    isRequired: true,
    type: "text",
    name: "lastName",
  },
  {
    label: "Phone Number",
    isRequired: true,
    type: "number",
    name: "phoneNumber",
  },
  {
    label: "Email Address",
    isRequired: true,
    type: "email",
    name: "email",
  },
  {
    label: "Password",
    isRequired: true,
    type: "password",
    name: "password",
  },
  {
    label: "Confirm Password",
    isRequired: true,
    type: "password",
    name: "confirmPassword",
  },
  {
    label: "Organisation Name",
    isRequired: false,
    type: "text",
    name: "organisationName",
  },
  {
    label: "Website URL",
    isRequired: false,
    type: "text",
    name: "website",
  },
]