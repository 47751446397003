export const FormInputFields = [
  {
    label: "First Name",
    isRequired: true,
    type: "text",
    name: "firstName",
  },
  {
    label: "Last Name",
    isRequired: true,
    type: "text",
    name: "lastName",
  },
  {
    label: "Phone Number",
    isRequired: true,
    type: "number",
    name: "phoneNumber",
  },
  {
    label: "Email Address",
    isRequired: true,
    type: "email",
    name: "email",
    pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
  },
  {
    label: "Password",
    isRequired: true,
    type: "password",
    name: "password",
  },
  {
    label: "Confirm Password",
    isRequired: true,
    type: "password",
    name: "confirmPassword",
  }
];
