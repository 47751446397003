import React, { useEffect } from 'react'
import { Buttons } from '../../../CustomUIElements/Buttons/Buttons'
import Inputs from '../../../CustomUIElements/Inputs/Inputs'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { updateInstitutionInfo } from '../../../utils/fetchInvestors'

function AddInstitutionForm({ formValues, setFormValues, isLoading, rowData, setOpenModal, refetch, mode }) {
  console.log("rowData", rowData)
  useEffect(() => {
    setFormValues({
      institutionName: rowData?.name,
      firstName: rowData?.firstName,
      lastName: rowData?.lastName,
      contactEmail: rowData?.contactEmail,
      contactNumber: rowData?.contactNumber,
      institutionAddress: rowData?.address,
      id: rowData?.id,
      userId: rowData?.userId
    })
  }, [])

  const { mutate, isLoading: updating } = useMutation(updateInstitutionInfo, {
    onSuccess: ({ data }) => {
      // saveUserProfile(data.data);
      setOpenModal()
      toast.success(`${data.status}`)
      setFormValues({})
      refetch()
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({
      name: formValues?.institutionName,
      firstName: formValues?.firstName,
      lastName: formValues?.lastName,
      contactEmail: formValues?.contactEmail,
      contactNumber: formValues?.contactNumber,
      userId: formValues?.userId,
      address: formValues?.institutionAddress,
      id: rowData?.id
    });
  };
  return (
    <form className="space-y-[1.6rem]" onSubmit={handleSubmit}>
      {FormInputFields.map(({ name, label, type, isRequired }) => (
        <Inputs
          key={name}
          type={type}
          label={label}
          name={name}
          isRequired={isRequired}
          value={formValues[name]}
          handleChange={(e) =>
            setFormValues({
              ...formValues,
              [e.target.name]: e.target.value,
            })
          }
          readOnly={mode === "view"}
        />
      ))}
      {mode !== "view" &&
        <div className="!mt-[3rem]">
          <Buttons
            variant={
              formValues?.firstName &&
              formValues?.lastName &&
              formValues?.contactEmail &&
              formValues?.contactNumber &&
              formValues?.institutionName &&
              formValues?.institutionAddress &&
                !updating
                ? "primary"
                : "disabled"
            }
            type="submit"
            text={updating ? "Submitting..." : "Submit"}
          />
        </div>
      }
    </form>
  )
}

export default AddInstitutionForm

export const FormInputFields = [
  {
    label: "Institution Name",
    isRequired: true,
    type: "text",
    name: "institutionName",
  },
  {
    label: "Institution Contact First Name",
    isRequired: true,
    type: "text",
    name: "firstName",
  },
  {
    label: "Institution Contact Last Name",
    isRequired: true,
    type: "text",
    name: "lastName",
  },
  {
    label: "Institution Address",
    isRequired: true,
    type: "text",
    name: "institutionAddress",
  },
  {
    label: "Institution Contact Number",
    isRequired: true,
    type: "number",
    name: "contactNumber",
  },
  {
    label: "Institution Contact Email",
    isRequired: true,
    type: "email",
    name: "contactEmail",
  },
]